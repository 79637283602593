import React, { useState } from "react";
import { Modal, Input, message } from "antd";
import { RequestForShareDataApi } from "../../Constants/Api/Api";

const RequestShareDataModal = ({ isOpen, onClose, onRequestSuccess }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleRequestShare = async () => {
    if (!email.trim()) {
      message.error("Please enter a valid email.");
      return;
    }

    setLoading(true);
    try {
      const res = await RequestForShareDataApi({ email });
      console.log(res,"===")
      if (res.data.code === 200) {
        message.success(`Request sent to ${email}`);
        setEmail("");
        onRequestSuccess(); // Refresh data in parent component
        onClose();
      } 
    } catch (error) {
    //   message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Request Data Share"
      open={isOpen}
      onCancel={onClose}
      onOk={handleRequestShare}
      confirmLoading={loading}
      okText="Request"
      cancelText="Cancel"
    >
      <p>Enter the email address to request data sharing:</p>
      <Input
        placeholder="Enter email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
    </Modal>
  );
};

export default RequestShareDataModal;
