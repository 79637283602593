// MessageTemplate.jsx
import React from "react";
import { Card, Container } from "react-bootstrap";
import { IoMdArrowRoundBack } from "react-icons/io";
import Logo from "../../assets/Logo.png";
import "./CommonMessage.scss";
import CustomButton from "../CustomButton/CustomButton";
import { useNavigate } from "react-router";
const MessageTemplate = ({
  subject,
  text,
  buttonText,
  onButtonClick,
  useHtmlText = true,
}) => {
  // const router = useRouter();
  const navigate = useNavigate();
  return (
    <div className="CommonMessage">
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <Card
          style={{
            maxWidth: "1000px",
            textAlign: "center",
            padding: "80px 60px",
          }}
        >
          <div className="main_heading">
            <div
              onClick={() => {
                navigate("/");
              }}
              style={{ cursor: "pointer" }}
            >
              <img src={Logo} height={50} alt="Logo" />
            </div>
            <div
              className="innerheading"
              // onClick={() => {
              //   router.push("/");
              // }}
              onClick={() => {
                navigate("/");
              }}
              style={{ cursor: "pointer" }}
            >
              <IoMdArrowRoundBack />
              <div>Go to Home</div>
            </div>
          </div>
          <div className="EmailSentMessage m-2">
            <h3 className="mb-3" style={{ color: "#3D3C6E" }}>
              {subject}
            </h3>
            {useHtmlText ? (
              <p
                dangerouslySetInnerHTML={{ __html: text }}
                style={{ textAlign: "left" }}
              ></p>
            ) : (
              <p style={{ textAlign: "left" }}>{text}</p>
            )}
            {/* <Button
            className="submit_btn_theme p-2"
            onClick={onButtonClick}
            style={{ border: "none", width: "fit-content" }}
          >
            {buttonText}
          </Button> */}
            <div style={{ justifySelf: "center" }}>
              <CustomButton
                onClick={onButtonClick}
                htmlType="submit"
                text={buttonText}
                bgColor="#3D3C6E"
                width="fit-content"
                padding="10px 40px"
              />
            </div>
            {subject === "Almost there! Verify Your Email" && (
              <div className="bottomText" style={{ marginTop: "10px" }}>
                Go to{" "}
                <span
                  // onClick={() => {
                  //   router.push("/login");
                  // }}
                  onClick={() => {
                    navigate("/login");
                  }}
                  style={{ cursor: "pointer", color: "blue" }}
                >
                  Login
                </span>
              </div>
            )}
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default MessageTemplate;
