import React, { useEffect, useState } from "react";
import { Space, Table, message, Modal, Input, Form, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import AuthLayout from "../layout/auth";
import {
  SearchOutlined,
  UserAddOutlined,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons"; // Imported icons
import {
  DeleteBranchApi,
  GetBranceshApi,
  GetAllBranchEmployeeApi,
  AddBranchEmployee,
} from "../../Constants/Api/Api";
import AddBranchEmployeeForm from "./AddBranchEmployeeForm";
const Branchlist = () => {
  const [data, setData] = useState([]);
  const [backupData, setBackupData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);
  const [isViewEmployeesModalOpen, setIsViewEmployeesModalOpen] =
    useState(false);
  const [selectedBranchId, setSelectedBranchId] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  // Fetch branches
  const fetchBranch = async () => {
    setLoading(true);
    try {
      const res = await GetBranceshApi();
      console.log(res,"==")
      const formattedData = res.data.data.map((item, index) => ({
        key: item.id,
        srNo: index + 1,
        id: item.id,
        branch_name: item.branch_name,

        name: item.name,
        email: item.email,
        company_name: item.company_name,
        mobile: item.mobile,
      }));
      setData(formattedData);
      setBackupData(formattedData);
    } catch (error) {
      message.error("Error fetching branches.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBranch();
  }, []);
  // Fetch employees of a branch
  const fetchEmployees = async (branchId) => {
    setLoading(true);
    try {
      const res = await GetAllBranchEmployeeApi(branchId);
      console.log(res, "===");
      setEmployees(res.data?.data || []);
    } catch (error) {
      message.error("Error fetching employees.");
    } finally {
      setLoading(false);
    }
  };
  // Handle Delete (for multiple or single)
  const handleDelete = (branchIds) => {
    const formData = { branch_id: branchIds };
    Modal.confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete ${
        branchIds.length > 1 ? "these branches" : "this branch"
      }?`,
      onOk: async () => {
        try {
          const response = await DeleteBranchApi(formData);
          if (response.data.code === 204) {
            message.success("Branches deleted successfully");
            fetchBranch();
            setSelectedRowKeys([]);
          }
        } catch (error) {
          message.error("Error deleting branch.");
        }
      },
    });
  };
  // Search functionality
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    setData(
      backupData.filter(
        (item) =>
          item.name.toLowerCase().includes(value.toLowerCase()) ||
          item.email.toLowerCase().includes(value.toLowerCase())
      )
    );
  };
  // Open "Add Employee" modal
  const openEmployeeModal = (branchId) => {
    setSelectedBranchId(branchId);
    setIsEmployeeModalOpen(true);
  };
  // Open "View Employees" modal
  const openViewEmployeesModal = (branchId) => {
    setSelectedBranchId(branchId);
    fetchEmployees(branchId);
    setIsViewEmployeesModalOpen(true);
  };
  // Submit new employee
  const handleAddEmployee = async (values) => {
    const employeeData = { ...values, branch_id: selectedBranchId };
    try {
      await AddBranchEmployee(employeeData);
      message.success("Employee added successfully!");
      setIsEmployeeModalOpen(false);
      form.resetFields();
    } catch (error) {
      message.error("Failed to add employee.");
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedKeys) => setSelectedRowKeys(newSelectedKeys),
  };
  const columns = [
    { title: "Sr No.", dataIndex: "srNo", key: "srNo" },
    {
      title: "Branch Name",
      dataIndex: "branch_name",
      key: "branch_name",
      sorter: (a, b) => a.branch_name.localeCompare(b.branch_name),
    },
    {
      title: "Branch Manager Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile",
      key: "mobile",
      sorter: (a, b) => a.mobile.localeCompare(b.mobile),
    },
    {
      title: "View Details",
      render: (_, record) => (
        <Button
          icon="pi pi-eye"
          rounded
          outlined
          className="mr-2"
          severity="success"
          style={{
            borderRadius: "25px",
            borderColor: "#689f38",
          }}
          onClick={() => navigate(`/branch-view/${record.id}`)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* Add Employee Button (Icon Only) */}
          <Tooltip title="Add Employee">
            <Button
              icon={<UserAddOutlined />}
              className="employee-btn"
              severity="info"
              onClick={() => openEmployeeModal(record.id)}
            />
          </Tooltip>
          {/* View Employees Button (Icon Only) */}
          <Tooltip title="View Employees">
            <Button
              icon={<EyeOutlined />}
              className="view-employee-btn"
              severity="success"
              onClick={() => openViewEmployeesModal(record.id)}
            />
          </Tooltip>
          {/* Delete Button (Icon Only) */}
          <Tooltip title="Delete Branch">
            <Button
              icon={<DeleteOutlined />}
              className="delete-btn"
              severity="danger"
              onClick={() => handleDelete([record.id])}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <AuthLayout>
      <h3 className="page-title">Branches</h3>
      <div className="header">
        <Input
          size="large"
          placeholder="Search..."
          prefix={<SearchOutlined />}
          className="searchInput"
          value={searchText}
          onChange={handleSearchChange}
        />
        <Button
          icon="pi pi-trash"
          className="deleteBtn"
          severity="danger"
          onClick={() => handleDelete(selectedRowKeys)}
          disabled={!selectedRowKeys.length}
        />
        <Button
          icon="pi pi-plus"
          className="addBtn"
          severity="info"
          onClick={() => navigate("/create-branch")}
        />
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{ pageSize: 10 }}
        loading={loading}
      />
      {/* Add Employee Modal */}
      <Modal
        title="Add Employee"
        open={isEmployeeModalOpen}
        onCancel={() => setIsEmployeeModalOpen(false)}
        footer={null}
      >
        <AddBranchEmployeeForm form={form} onSubmit={handleAddEmployee} />
      </Modal>
      {/* View Employees Modal */}

      <Modal
        title="Branch Employees"
        open={isViewEmployeesModalOpen}
        onCancel={() => setIsViewEmployeesModalOpen(false)}
        footer={null}
      >
        <Table
          dataSource={employees?.map((emp) => ({
            ...emp,
            displayName: emp.user_profile?.name, // Mapping userprofile.name to a new key
          }))}
          columns={[
            { title: "Name", dataIndex: "displayName", key: "displayName" },
            { title: "Email", dataIndex: "email", key: "email" },
            {
              title: "Action",
              key: "action",
              render: (_, record) => (
                <Tooltip title="View Employee">
                  <Button
                    type="primary"
                    icon={<EyeOutlined />}
                    onClick={() => navigate(`/viewemployee/${record.id}`)}
                  />
                </Tooltip>
              ),
            },
          ]}
          rowKey="id"
        />
      </Modal>
    </AuthLayout>
  );
};
export default Branchlist;
