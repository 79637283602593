import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./Pages/Dashboard/dashboard";
import Forgot from "./Pages/Forgot/Forgot";
import Reset from "./Pages/reset/reset";
import Profile from "./Pages/Profile/Profile";
import Login from "./Pages/Login/Login";
import EmployeeList from "./Pages/EmployeeList/EmployeeList";
import AddEmployee from "./Components/AddEmployee/AddEmployee";
import ViewEmployee from "./Pages/ViewEmployee/ViewEmployee";
import ShareData from "./Pages/ShareData/ShareData";
import CoursesList from "./Components/CoursesList/CourseList";
import AssignCourseToEmployee from "./Components/AssignCourseToEmployee/AssignCourseToEmployee";
import PurchaseHistory from "./Pages/PurchaseHistory/PurchaseHistory";
import SignUp from "./Pages/SignUp/SignUp";
import VerifyOtp from "./Pages/VerifyOtp/VerifyOtp";
import EmailVerification from "./Components/LinkExpired/EmailVerification";
import Branchlist from "./Pages/Branchlist/Branchlist";
import CreateBranch from "./Components/CreateBranch/CreateBranch";
import BranchView from "./Pages/BranchView/BranchView";
import ViewShareDataRequest from "./Pages/ShareData/ViewShareDataRequest/ViewShareDataRequest";

const isAuthenticated = () => {
  return !!localStorage.getItem("corporateToken");
};

const PrivateRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/login" />;
};

const WebRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/"
          element={isAuthenticated() ? <Navigate to="/dashboard" /> : <Login />}
        />

        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/email-verify" element={<EmailVerification />} />
        <Route exact path="/verifyotp" element={<VerifyOtp />} />
        <Route exact path="/login" element={<Login />} />

        <Route
          exact
          path="/dashboard"
          element={<PrivateRoute element={<Dashboard />} />}
        />
        <Route
          exact
          path="/profile"
          element={<PrivateRoute element={<Profile />} />}
        />
        <Route
          exact
          path="/employeelist"
          element={<PrivateRoute element={<EmployeeList />} />}
        />
        <Route
          exact
          path="/branchlist"
          element={<PrivateRoute element={<Branchlist />} />}
        />
         <Route
          exact
          path="/create-branch"
          element={<PrivateRoute element={<CreateBranch />} />}
        />
        <Route
          exact
          path="/sharedata"
          element={<PrivateRoute element={<ShareData />} />}
        />
         <Route
          exact
          path="/view-sharedata-request"
          element={<PrivateRoute element={<ViewShareDataRequest />} />}
        />
        <Route
          exact
          path="/courseslist"
          element={<PrivateRoute element={<CoursesList />} />}
        />
        <Route
          exact
          path="/assigncoursetoemployee/:id"
          element={<PrivateRoute element={<AssignCourseToEmployee />} />}
        />
        <Route
          exact
          path="/addemployee"
          element={<PrivateRoute element={<AddEmployee />} />}
        />
        <Route
          exact
          path="/viewemployee/:id"
          element={<PrivateRoute element={<ViewEmployee />} />}
        />
         <Route
          exact
          path="/branch-view/:id"
          element={<PrivateRoute element={<BranchView />} />}
        />
        <Route
          exact
          path="/purchasehistory"
          element={<PrivateRoute element={<PurchaseHistory />} />}
        />

        <Route exact path="/verifyotp" element={<VerifyOtp/>} />
        <Route exact path="/forgotpassword" element={<Forgot />} />
        <Route exact path="/reset" element={<Reset />} />
      </Routes>
    </BrowserRouter>
  );
};

export default WebRoutes;
