import React, { useState } from "react";
import MessageTemplate from "../CommoneMessage/CommonMessage";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Spin } from "antd"; // Import the Spin component from Ant Design
import { VerifyEmail } from "../../Constants/Api/Api";

const EmailVerification = () => {
  const [verified, setVerified] = useState(false); // State to track if verification was successful
  const [alreadyVerified, setAlreadyVerified] = useState(false); // State to track if email is already verified
  const [loading, setLoading] = useState(false); // State to track if the verification is in progress
  const [searchParams] = useSearchParams(); // Destructure the array
  const token = searchParams.get("token"); // Use the `get` method directly on `searchParams`
  const navigate = useNavigate();

  const handleVerify = () => {
    setLoading(true); // Start loading
    const formData = { token };
    VerifyEmail(formData)
      .then((res) => {
        setVerified(true); // Set verified to true on successful response
      })
      .catch((e) => {
        console.log(e);
        if (e?.response?.data?.message === "Email Already Verified") {
          setAlreadyVerified(true); // Set alreadyVerified to true if email is already verified
        }
        if (e?.response?.data?.status === 410) {
          setTimeout(() => navigate("/signup"), 3000);
        }
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  };

  return (
    <>
      {verified ? (
        <MessageTemplate
          subject="Email Verified Successfully!"
          text="Your email has been successfully verified. You may now log in to access your Nuclear Gauges account."
          buttonText="Back to Login"
          onButtonClick={() => navigate("/login")}
        />
      ) : alreadyVerified ? (
        <MessageTemplate
          subject="Email Already Verified"
          text="It looks like your email has already been verified. You can go ahead and Log In to access your Nuclear Gauges account."
          buttonText="Back to Login"
          onButtonClick={() => navigate("/login")}
        />
      ) : (
        <MessageTemplate
          subject="Verify Your Email Address"
          text="Click the button below to confirm your email address and activate your account with Nuclear Gauges."
          buttonText={
            <span>
              {loading && <Spin size="small" style={{ marginRight: 8 }} />} Verify My Email
            </span>
          }
          onButtonClick={handleVerify}
          buttonDisabled={loading} // Disable the button while loading
        />
      )}
    </>
  );
};

export default EmailVerification;
