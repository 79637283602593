import React, { useEffect, useLayoutEffect, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Card } from "primereact/card";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Modal, Table, message, Tooltip, Form } from "antd";
import AuthLayout from "../layout/auth";
import {
  GetBranchDetailsApi,
  GetAllBranchEmployeeApi,
  AddBranchEmployee,
  GetBranchSharedDataApi,
  GetBranchPurchaseHistoryApi,
} from "../../Constants/Api/Api";
import { EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import AddBranchEmployeeForm from "../Branchlist/AddBranchEmployeeForm";
import "./BranchView.scss";

const BranchView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm(); // ✅ AntD form instance
  const [companyData, setCompanyData] = useState({});
  const [employees, setEmployees] = useState([]);
  const [sharedData, setSharedData] = useState([]); // ✅ Shared data state
  const [isAddEmployeeModalOpen, setIsAddEmployeeModalOpen] = useState(false);
  const [purchaseData, setPurchaseData] = useState([]);
  const [activeTab, setActiveTab] = useState("course");

  // Fetch branch details
  useLayoutEffect(() => {
    GetBranchDetailsApi(id)
      .then((res) => setCompanyData(res?.data?.data[0] || {}))
      .catch(() => message.error("Error fetching branch details"));
  }, [id]);

  // Fetch employees
  const fetchEmployees = () => {
    GetAllBranchEmployeeApi(id)
      .then((res) => setEmployees(res?.data?.data || []))
      .catch(() => message.error("Error fetching employees"));
  };

  // Fetch shared data
  const fetchSharedData = () => {
    GetBranchSharedDataApi(id)
      .then((res) => setSharedData(Array.isArray(res?.data?.data) ? res.data.data : []))
      .catch(() => setSharedData([]));
  };

  // Fetch purchase history data
  const getData = async (type) => {
    try {
      const response = await GetBranchPurchaseHistoryApi({ type, id });
      setPurchaseData(
        response.data.data.map((item, index) => ({
          ...item,
          autoIncrementId: index + 1,
        }))
      );
    } catch {
      message.error("Error fetching purchase data");
    }
  };

  useEffect(() => {
    fetchEmployees();
    fetchSharedData();
    getData(activeTab);
  }, [activeTab]);

  // Open modal
  const openAddEmployeeModal = () => {
    setIsAddEmployeeModalOpen(true);
    form.resetFields();
  };

  // Close modal
  const closeAddEmployeeModal = () => {
    setIsAddEmployeeModalOpen(false);
  };

  // Handle adding a new employee
  const handleAddEmployee = async (values) => {
    try {
      await AddBranchEmployee({ branch_id: id, ...values });
      message.success("Employee added successfully!");
      closeAddEmployeeModal();
      fetchEmployees();
    } catch {
      message.error("Failed to add employee");
    }
  };

  const handleTabSelect = (k) => {
    setActiveTab(k);
    getData(k);
  };

  const tabsContent = [
    { label: "Course", key: "course" },
    { label: "Test", key: "test" },
  ];

  const columnsCourse = [
    { title: "ID", dataIndex: "autoIncrementId", key: "id" },
    { title: "Course Name", dataIndex: ["purchase_history_course", "name"], key: "course_name", render: (text) => text || "---" },
    { title: "Employee", dataIndex: ["history_user", "user_profile", "name"], key: "employee_name", render: (text) => text || "--" },
    { title: "Course Price", dataIndex: "amount", key: "amount", render: (amount) => amount || "---" },
    { title: "Quantity", dataIndex: "quantity", key: "quantity", render: (quantity) => quantity || "---" },
    { title: "Purchase Date", dataIndex: "created_at", key: "date", render: (date) => (date ? moment(date).format("DD-MM-YYYY") : "--") },
  ];

  const columnsTest = [
    { title: "ID", dataIndex: "autoIncrementId", key: "id" },
    { title: "Test Name", dataIndex: ["purchase_history_test", "name"], key: "test_name", render: (text) => text || "---" },
    { title: "Employee", dataIndex: ["history_user", "user_profile", "name"], key: "employee_name", render: (text) => text || "--" },
    { title: "Test Duration", dataIndex: ["purchase_history_test", "duration"], key: "test_duration", render: (text) => text || "---" },
    { title: "Quantity", dataIndex: "quantity", key: "quantity", render: (quantity) => quantity || "---" },
    { title: "Purchase Date", dataIndex: "created_at", key: "date", render: (date) => (date ? moment(date).format("DD-MM-YYYY") : "--") },
  ];

  return (
    <AuthLayout>
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="30px">
          <div>
            <h3 className="page-title">Branch Management</h3>
            <p className="page-sub-title">View branch details and employees</p>
          </div>
          <Button onClick={() => navigate("/branchlist")} className="return-button">
            Return to Branch List
          </Button>
        </Box>

        <Card style={{ width: "100%", marginBottom: "40px" }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "20px",
                }}
              >
                  <div>
                  <h5>Branch Name:</h5>
                  <p>{companyData?.branch_name || "---"}</p>
                </div>
                <div>
                  <h5>Branch Manager Name:</h5>
                  <p>{companyData?.name || "---"}</p>
                </div>
                <div>
                  <h5>Branch Email:</h5>
                  <p>{companyData?.email || "---"}</p>
                </div>
                <div>
                  <h5>Branch Address:</h5>
                  <p>
                    {companyData?.address_line_1 || "---"}{" "}
                    {companyData?.address_line_2 || "---"}{" "}
                    {companyData?.user_city?.name || "---"}{" "}
                    {companyData?.user_state?.name || "---"}{" "}
                    {companyData?.user_country?.name || "---"}{" "}
                    {companyData?.postal_code || "---"}
                  </p>
                </div>
              </div>
            </Card>
        <Card>
          <div className="table-header">
            <h5>Branch Employees</h5>
            <Button onClick={openAddEmployeeModal} className="add-button">
              Add Employee
            </Button>
          </div>
          <Table
            dataSource={employees}
            columns={[
              { title: "Name", dataIndex: ["user_profile", "name"], key: "name" },
              { title: "Email", dataIndex: "email", key: "email" },
              {
                title: "Action",
                key: "action",
                render: (_, record) => (
                  <Tooltip title="View Employee">
                    <Button onClick={() => navigate(`/viewemployee/${record.id}`)}>
                      <EyeOutlined />
                    </Button>
                  </Tooltip>
                ),
              },
            ]}
            rowKey="id"
          />
        </Card>

        <Card>
          <h5>Purchase History</h5>
          <Tabs activeKey={activeTab} onSelect={handleTabSelect}>
            {tabsContent.map((tab) => (
              <Tab eventKey={tab.key} title={tab.label} key={tab.key}>
                <Table columns={activeTab === "course" ? columnsCourse : columnsTest} dataSource={purchaseData} rowKey="autoIncrementId" />
              </Tab>
            ))}
          </Tabs>
        </Card>

        {/* Add Employee Modal */}
        <Modal centered title="Add Employee" open={isAddEmployeeModalOpen} onCancel={closeAddEmployeeModal} footer={null}>
          <AddBranchEmployeeForm form={form} onSubmit={handleAddEmployee} />
        </Modal>
      </Box>
    </AuthLayout>
  );
};

export default BranchView;
