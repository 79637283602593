import {
  HomeOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  AuditOutlined,
  FundViewOutlined,
} from "@ant-design/icons";
import { LiaHistorySolid } from "react-icons/lia";
import { FaRegShareSquare } from "react-icons/fa";
import { Dropdown, Button, Layout, Menu, theme } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/Logo.png";
import { IoIosLogOut } from "react-icons/io";
import "./auth.scss";
import { GetProfileAPI } from "../../Constants/Api/Api";

const { Header, Sider, Content } = Layout;

const AuthLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const location = useLocation();

  const currentRoute = location.pathname?.split("/")?.[1];

  // ✅ Fix: Initialize state correctly
  const [isbranch, setIsbranch] = useState(null);
  const [checkBranch, setCheckBranch] = useState(null);

  const handleMenuClick = (e) => {
    if (e?.key === "/logout") {
      localStorage.clear();
      navigate("/");
    } else {
      navigate(e?.key);
    }
  };

  // ✅ Fetch profile & update `isbranch`
  useEffect(() => {
    const token = localStorage.getItem("corporateToken");
    const headers = {
      "x-access-token": token,
      role_id: 8,
    };

    GetProfileAPI(headers)
      .then((res) => {
        const userProfile = res.data?.data;
        console.log(userProfile?.is_branch, "userProfile");

        if (userProfile?.is_branch !== undefined && userProfile?.is_branch !== null) {
          setIsbranch(userProfile?.is_branch);
          localStorage.setItem("branch", userProfile?.is_branch.toString());
          setCheckBranch(userProfile?.is_branch.toString());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // ✅ Ensure `checkBranch` updates when `isbranch` changes
  useEffect(() => {
    if (isbranch !== null) {
      setCheckBranch(isbranch.toString());
      localStorage.setItem("branch", isbranch.toString());
    }
  }, [isbranch]);

  console.log(checkBranch, "profileData");

  const menuItems = [
    { label: "Profile", key: "/profile", icon: <UserOutlined size={22} /> },
    { label: "Logout", key: "/logout", icon: <IoIosLogOut size={18} /> },
    { label: "Reset Password", key: "/reset", icon: <FundViewOutlined size={22} /> },
  ];

  return (
    <Layout>
      {/* Sidebar Navigation */}
      <Sider trigger={null} collapsible collapsed={collapsed} width={230}>
        <div className="logo">
          <img src={logo} className="logo-image" alt="logo" />
        </div>
        <Menu
          onClick={handleMenuClick}
          theme="dark"
          mode="inline"
          selectedKeys={[`/${currentRoute}`]}
          style={{ fontSize: "16px", fontWeight: 500, marginTop: "10px" }}
        >
          <Menu.Item key="/dashboard" icon={<HomeOutlined />} label="Dashboard">
            Dashboard
          </Menu.Item>
          <Menu.Item key="/employeelist" icon={<UserOutlined />} label="Employee">
            Employee
          </Menu.Item>
          {checkBranch === "false" && (
            <Menu.Item key="/branchlist" icon={<UserOutlined />} label="Branch">
              Branch
            </Menu.Item>
          )}
          <Menu.Item key="/sharedata" icon={<FaRegShareSquare />} label="Share Data">
            Share Data
          </Menu.Item>
          <Menu.Item key="/view-sharedata-request" icon={<FaRegShareSquare />} label="View Data Request">
            View Data Request
          </Menu.Item>
          <Menu.Item key="/courseslist" icon={<AuditOutlined />} label="Assign Course">
            Assign Course
          </Menu.Item>
          <Menu.Item key="/purchasehistory" icon={<LiaHistorySolid />} label="Purchase History">
            Purchase History
          </Menu.Item>
        </Menu>
      </Sider>

      {/* Header & Main Content */}
      <Layout>
        <Header
          style={{
            padding: 0,
            background: token.colorBgContainer,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {/* Sidebar Toggle Button */}
          <Button
            type="text"
            icon={<MenuUnfoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />

          {/* User Dropdown Menu */}
          <Dropdown.Button
            menu={{ items: menuItems, onClick: handleMenuClick }}
            style={{ justifyContent: "flex-end", marginRight: 20 }}
            placement="bottomLeft"
            icon={<UserOutlined />}
          >
            User
          </Dropdown.Button>
        </Header>

        {/* Page Content */}
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 600,
            background: token.colorBgContainer,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AuthLayout;
