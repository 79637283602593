import React from "react";
import { Form, Input, Button } from "antd";

const AddBranchEmployeeForm = ({ form, onSubmit }) => {
  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        name="name"
        label="Employee Name"
        rules={[{ required: true, message: "Name is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[{ required: true, message: "Email is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Add Employee
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddBranchEmployeeForm;
