import React, { useEffect, useState } from "react";
import { message, Form, Input, Button, Card, Select } from "antd";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../Pages/layout/auth";
import {
  CreateBranchApi,
  GetCountryCode,
  GetStatesByCountry,
  GetCitiesByState,
} from "../../Constants/Api/Api";
import PhoneInput from "react-phone-input-2";
import "./CreateBranch.scss";

const { Option } = Select;

const CreateBranch = () => {
  const [form] = Form.useForm();
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("corporateToken");
  const headers = {
    "x-access-token": token,
    role_id: 8,
  };

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  // Fetch countries (Canada only)
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await GetCountryCode();
        const filteredCountries = (response?.data?.data || []).filter(
          (country) => country.id === 39 || country.id === 233
        );
        setCountries(filteredCountries);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };
    fetchCountries();
  }, []);

  // Handle country change
  const handleCountryChange = async (value) => {
    setSelectedCountry(value);
    form.setFieldsValue({ state_id: null, city_id: null });
    setStates([]);
    setCities([]);
    setSelectedState(null);

    try {
      const response = await GetStatesByCountry(value);
      setStates(response.data?.data?.all_state || []);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  // Handle state change
  const handleStateChange = async (value) => {
    setSelectedState(value);
    form.setFieldsValue({ city_id: null });
    setCities([]);

    try {
      const response = await GetCitiesByState(value);
      setCities(response.data?.data?.all_city || []);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const handleSubmit = async (values) => {
    setDisable(true);

    const formData = {
      branch_name: values.branch_name,
      name: values.name,
      email: values.email,
      mobile: values.mobile,
      address_line_1: values.address_line_1,
      address_line_2: values.address_line_2,
      country_id: values.country_id,
      state_id: values.state_id,
      city_id: values.city_id,
      postal_code: values.postal_code,
    };

    try {
      const response = await CreateBranchApi(formData, headers);
      message.success(response?.data?.message);
      setTimeout(() => {
        navigate("/branchlist");
      }, 1000);
    } catch (error) {
      message.error("Failed to create branch");
    } finally {
      setDisable(false);
    }
  };

  return (
    <AuthLayout>
      <div className="add-employee-container">
        <div className="header">
          <h3 className="page-title">Add Branch</h3>
          <p className="page-sub-title">Create New Branch</p>
          <Button onClick={() => navigate("/branchlist")}>
            Return to Branch List
          </Button>
        </div>
        <Card className="card-container">
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            // initialValues={{
            //   name: "Branch Manager Canada",
            //   email: "rejehew520@erapk.com",
            //   mobile: "+18109678826",
            //   address_line_1: "202",
            //   address_line_2: "Tower Street",
            //   country_id: 124,
            //   state_id: 1399,
            //   city_id: 112098,
            //   postal_code: "87644",
            // }}
          >
            {/* Required Fields */}
            <Form.Item
  label="Branch Name"
  name="branch_name"
  rules={[{ required: true, message: "Please enter branch name" }]} // ✅ Moved rules here
>
  <Input placeholder="Enter branch name" />
</Form.Item>

            <Form.Item label="Branch Manager Name" name="name">
              <Input placeholder="Enter Branch Manager name" />
            </Form.Item>

            <Form.Item label="Email Address" name="email">
              <Input placeholder="Enter email" />
            </Form.Item>

            <Form.Item label="Mobile Number" name="mobile">
            <PhoneInput
                    onlyCountries={["us", "ca"]}
                    country="us"
                    inputStyle={{ width: "100%" }}
                  />
            </Form.Item>

            <Form.Item label="Address Line 1" name="address_line_1">
              <Input placeholder="Address Line 1" />
            </Form.Item>

            <Form.Item label="Address Line 2" name="address_line_2">
              <Input placeholder="Address Line 2 (Optional)" />
            </Form.Item>

            <Form.Item label="Country" name="country_id">
              <Select
                placeholder="Select Country"
                onChange={handleCountryChange}
              >
                {countries.map((country) => (
                  <Option key={country.id} value={country.id}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="State/Province" name="state_id">
              <Select
                placeholder="Select State"
                onChange={handleStateChange}
                disabled={!selectedCountry}
              >
                {states.map((state) => (
                  <Option key={state.id} value={state.id}>
                    {state.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="City"
              name="city_id"
              
            >
              <Select placeholder="Select City" disabled={!selectedState}>
                {cities.map((city) => (
                  <Option key={city.id} value={city.id}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={selectedCountry === 233 ? "Zip Code" : "Postal Code"}
              name="postal_code"
              rules={[
                {
                  
                  message: `Please input your ${
                    selectedCountry === 233 ? "Zip" : "Postal"
                  } Code!`,
                },
                {
                  validator: (_, value) => {
                    if (!value) return Promise.resolve(); // Skip if empty (required rule will catch it)

                    // US - 5 digits, Canada - 6 characters (alphanumeric)
                    if (selectedCountry === 233 && !/^\d{5}$/.test(value)) {
                      return Promise.reject(
                        new Error("US Zip Code must be exactly 5 digits.")
                      );
                    }
                    if (
                      selectedCountry === 39 &&
                      !/^[A-Za-z0-9]{6}$/.test(value)
                    ) {
                      return Promise.reject(
                        new Error(
                          "Canadian Postal Code must be exactly 6 characters."
                        )
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input placeholder="Postal Code" />
            </Form.Item>

            {/* Buttons */}
            <div className="btn-space">
              <Button type="primary" htmlType="submit" disabled={disable}>
                {disable ? "Saving..." : "Save"}
              </Button>
              <Button onClick={() => navigate("/employeelist")}>Cancel</Button>
            </div>
          </Form>
        </Card>
      </div>
    </AuthLayout>
  );
};

export default CreateBranch;
