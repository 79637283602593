import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, message, Button, Spin, Select } from "antd";
import { useNavigate } from "react-router";
import PhoneInput from "react-phone-input-2";
import {
  GetCitiesByState,
  GetCountryCode,
  GetStatesByCountry,
} from "../../Constants/Api/Api";
import { SignUpAPI } from "../../Constants/Api/Api";
import SignUpImg from "../../assets/SignupImg.png";
import Logo from "../../assets/Logo.png";
import "./SignUp.scss";

const { Option } = Select;

const SignUp = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  // Fetch countries (only Canada and USA)
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await GetCountryCode();
        const filteredCountries = (response?.data?.data || []).filter(
          (country) => country.id === 39 || country.id === 233
        );
        setCountries(filteredCountries);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };
    fetchCountries();
  }, []);

  // Function to fetch states
  const handleCountryChange = async (value) => {
    console.log("Selected Country ID:", value);
    setSelectedCountry(value);
    form.setFieldsValue({ state_id: null, city_id: null });
    setStates([]);
    setCities([]);
    setSelectedState(null);
    setSelectedCity(null);

    try {
      const response = await GetStatesByCountry(value);
      console.log("States API Response:", response);

      setStates(response.data?.data?.all_state || []);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  // Function to fetch cities
  const handleStateChange = async (value) => {
    console.log("Selected State ID:", value);
    setSelectedState(value);
    form.setFieldsValue({ city_id: null });
    setCities([]);
    setSelectedCity(null);

    try {
      const response = await GetCitiesByState(value);
      console.log("Cities API Response:", response);

      setCities(response.data?.data?.all_city || []);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  // Handle form submission
  const onFinish = async (values) => {
    setLoading(true);
    const formData = {
      name: values.fullname,
      email: values.email,
      mobile: values.phone,
      password: values.password,
      confirm_password: values.confirmPassword,
      company_name: values.companyname,
      address_line_1: values.address_line_1,
      address_line_2: values.address_line_2,
      city_id: values.city_id,
      state_id: values.state_id,
      country_id: values.country_id,
      postal_code: values.postal_code,
    };

    try {
      const res = await SignUpAPI(formData);
      message.success(res.data.message);
      // navigate("/verifyotp", { state: formData.email });
      navigate("/login");
    } catch (error) {
      console.error("Sign-up error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="signup">
      <Row className="row">
        <Col span={12} className="leftSide">
          <img src={SignUpImg} alt="signup" className="loginImg" />
        </Col>
        <Col span={12} className="column2">
          <div className="rightSide">
            <img src={Logo} width={200} alt="logo" />
            <h1 className="heading">Sign Up</h1>
            <p className="para">Enter your details to get started</p>

            <Spin spinning={loading}>
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                  label="Full Name"
                  name="fullname"
                  rules={[
                    { required: true, message: "Please input your full name!" },
                  ]}
                >
                  <Input placeholder="Full Name" />
                </Form.Item>

                <Form.Item
                  label="Company Name"
                  name="companyname"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company name!",
                    },
                  ]}
                >
                  <Input placeholder="Company Name" />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please enter a valid email!",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                  label="Phone Number"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <PhoneInput
                    onlyCountries={["us", "ca"]}
                    country="us"
                    inputStyle={{ width: "100%" }}
                  />
                </Form.Item>

                {/* Address Fields */}
                <Form.Item
                  label="Address Line 1"
                  name="address_line_1"
                  rules={[
                    { required: true, message: "Please enter Address Line 1!" },
                  ]}
                >
                  <Input placeholder="Address Line 1" />
                </Form.Item>

                <Form.Item label="Address Line 2" name="address_line_2">
                  <Input placeholder="Address Line 2 (Optional)" />
                </Form.Item>

                {/* Country Dropdown */}
                <Form.Item
                  label="Country"
                  name="country_id"
                  rules={[
                    { required: true, message: "Please select your Country!" },
                  ]}
                >
                  <Select
                    placeholder="Select Country"
                    onChange={handleCountryChange}
                  >
                    {countries?.map((country) => (
                      <Option key={country.id} value={country.id}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {/* State Dropdown */}
                <Form.Item
                  label="State/Province"
                  name="state_id"
                  rules={[
                    {
                      required: true,
                      message: "Please select your State/Province!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select State"
                    onChange={handleStateChange}
                    disabled={!selectedCountry}
                  >
                    {states?.map((state) => (
                      <Option key={state.id} value={state.id}>
                        {state.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {/* City Dropdown */}
                <Form.Item
                  label="City"
                  name="city_id"
                  rules={[
                    { required: true, message: "Please select your City!" },
                  ]}
                >
                  <Select
                    placeholder="Select City"
                    onChange={setSelectedCity}
                    disabled={!selectedState}
                  >
                    {cities?.map((city) => (
                      <Option key={city?.id} value={city?.id}>
                        {city?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={selectedCountry === 233 ? "Zip Code" : "Postal Code"}
                  name="postal_code"
                  rules={[
                    {
                      required: true,
                      message: `Please input your ${
                        selectedCountry === 233 ? "Zip" : "Postal"
                      } Code!`,
                    },
                    {
                      validator: (_, value) => {
                        if (!value) return Promise.resolve(); // Skip if empty (required rule will catch it)

                        // US - 5 digits, Canada - 6 characters (alphanumeric)
                        if (selectedCountry === 233 && !/^\d{5}$/.test(value)) {
                          return Promise.reject(
                            new Error("US Zip Code must be exactly 5 digits.")
                          );
                        }
                        if (
                          selectedCountry === 39 &&
                          !/^[A-Za-z0-9]{6}$/.test(value)
                        ) {
                          return Promise.reject(
                            new Error(
                              "Canadian Postal Code must be exactly 6 characters."
                            )
                          );
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input placeholder="Postal Code" />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                    {
                      min: 8,
                      message: "Password must be at least 8 characters!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>

                <Form.Item
                  label="Confirm Password"
                  name="confirmPassword"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        return value === getFieldValue("password")
                          ? Promise.resolve()
                          : Promise.reject("Passwords do not match!");
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Confirm Password" />
                </Form.Item>

                <Button htmlType="submit" type="primary" loading={loading}>
                  Submit
                </Button>
              </Form>
            </Spin>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SignUp;
