import React, { useEffect, useState } from "react";
import AuthLayout from "../../layout/auth";
import { Table } from "antd";
import {
  ViewAllRequestshareDataApi,
} from "../../../Constants/Api/Api";
import moment from "moment";
import RequestShareDataModal from "../RequestShareDataModal";
import { Button } from "primereact/button";

const ViewShareDataRequest = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
  
  useEffect(() => {
    fetchData();
  }, []);
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      key: "name",
      render: (_, record) =>
        record?.user_shared_data_request?.user_profile?.name || "N/A", // ✅ Extract profile name safely
    },
    {
      title: "Email",
      key: "email",
      render: (_, record) => record?.user_shared_data_request?.email || "N/A", // ✅ Extract email safely
    },
    {
      title: "Mobile",
      key: "mobile",
      render: (_, record) =>
        record?.user_shared_data_request?.user_profile?.mobile || "N/A", // ✅ Extract mobile safely
    },
    {
      title: "Date",
      key: "date",
      render: (_, record) =>
        record?.user_shared_data_request?.user_profile?.created_at
          ? moment(record?.user_shared_data_request?.user_profile?.created_at).format("DD-MM-YYYY")
          : "N/A", // ✅ Format date safely
    },
    {
      title: "Status",
      dataIndex: "request_status",
      key: "request_status",
      render: (status) => status || "Pending", // ✅ Default to "Pending"
    },
  ];

//   console.log(columns,"columns")
  
  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await ViewAllRequestshareDataApi();
      console.log(res?.data?.data,"res====dataaa")
      if (res.data.code === 200) {
        setData(res?.data?.data); // ✅ No need to remap, handled in `render`
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <AuthLayout>
        <div className="page-header">
          <h3>Share Request</h3>
          <p>View the shared request information below</p>
          <Button
          label="Request Data Share"
          icon="pi pi-send"
          className="p-button-primary"
          onClick={() => setModalOpen(true)}
        />
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            rowKey="id"
            pagination={{ pageSize: 10 }}
          />
        </div>
        <RequestShareDataModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onRequestSuccess={fetchData} // Refresh data when request is successful
      />
      </AuthLayout>
    </div>
  );
};

export default ViewShareDataRequest;
