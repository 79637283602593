import React, { useEffect, useState } from "react";
import { Space, Table, message } from "antd";
import moment from "moment";
import AuthLayout from "../layout/auth";
import { GetAllShareData } from "../../Constants/Api/Api";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import RequestShareDataModal from "./RequestShareDataModal"; // Import modal component
import "./ShareData.scss";

const ShareData = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("corporateToken");

  const headers = {
    "x-access-token": token,
    role_id: 8,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await GetAllShareData(headers);
      if (res.data.code === 200) {
        const formattedData = res.data.data.map((item) => ({
          id: item.id,
          name: item.user_shared_data.user_profile.name,
          email: item.user_shared_data.email,
          user_id: item.user_id,
          date: moment(item.user_shared_data.created_at).format("DD-MM-YYYY"),
        }));
        setData(formattedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleView = (record) => {
    navigate(`/viewemployee/${record.user_id}`);
  };

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Date", dataIndex: "date", key: "date" },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon="pi pi-eye"
            rounded
            outlined
            severity="success"
            style={{ borderRadius: "25px", borderColor: "#689f38" }}
            onClick={() => handleView(record)}
          />
        </Space>
      ),
    },
  ];

  return (
    <AuthLayout>
      <div className="page-header">
        <h3>Share Data</h3>
        <p>View the shared data information below</p>
        <Button
          label="Request Data Share"
          icon="pi pi-send"
          className="p-button-primary"
          onClick={() => setModalOpen(true)}
        />
      </div>

      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey="id"
        pagination={{ pageSize: 10 }}
      />

      {/* Request Data Share Modal */}
      <RequestShareDataModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onRequestSuccess={fetchData} // Refresh data when request is successful
      />
    </AuthLayout>
  );
};

export default ShareData;
